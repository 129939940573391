.img-to-pixel-art-wrapper {
  /* You can add any styles for the wrapper here if needed */
}

.img-to-pixel-art-wrapper .img-to-pixel-art {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.img-to-pixel-art-wrapper h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 15px;
}

.img-to-pixel-art-wrapper .introduction {
  margin-bottom: 20px;
  line-height: 1.6;
}

.img-to-pixel-art-wrapper .introduction p {
    margin-bottom: 15px; /* Add space between paragraphs */
    text-align: left; /* Align text to the left */
    max-width: 800px; /* Limit the maximum width of the text container */
    margin: 0 auto; /* Center the container within the page */
    line-height: 1.6; /* Improve readability by increasing line height */
}

.img-to-pixel-art-wrapper .instructions {
  background-color: #f8f9fa;
  border-radius: 5px;
  margin: 15px 0;
  text-align: left;
}

.img-to-pixel-art-wrapper .instructions h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #2c3e50;
  text-align: left;
}

.img-to-pixel-art-wrapper .instructions ol {
  padding-left: 20px;
  margin-left: 0;
  margin-bottom: 10px;
}

.img-to-pixel-art-wrapper .instructions ul {
  padding-left: 20px;
}

.img-to-pixel-art-wrapper .instructions li {
  margin-bottom: 5px;
  text-align: left;
}

.img-to-pixel-art-wrapper .converter {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.img-to-pixel-art-wrapper .controls {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.img-to-pixel-art-wrapper .file-input {
  display: none;
}

.img-to-pixel-art-wrapper .file-input-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.img-to-pixel-art-wrapper .file-input-label:hover {
  background-color: #2980b9;
}

.img-to-pixel-art-wrapper .pixel-size-control {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.img-to-pixel-art-wrapper .pixel-size-slider {
  width: 100%;
}

.img-to-pixel-art-wrapper .canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  border: 2px dashed #ccc;
  border-radius: 5px;
}

.img-to-pixel-art-wrapper .pixel-art-canvas {
  max-width: 100%;
  height: auto;
}

.img-to-pixel-art-wrapper .placeholder {
  color: #999;
  text-align: center;
}

.img-to-pixel-art-wrapper .download-button {
  display: block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.img-to-pixel-art-wrapper .download-button:hover {
  background-color: #45a049;
}

.img-to-pixel-art-wrapper .file-controls {
  display: flex;
  gap: 10px;
}

.img-to-pixel-art-wrapper .file-input-label,
.img-to-pixel-art-wrapper .download-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.img-to-pixel-art-wrapper .file-input-label:hover,
.img-to-pixel-art-wrapper .download-button:hover {
  background-color: #2980b9;
}

.img-to-pixel-art-wrapper .download-button {
  background-color: #4CAF50;
}

.img-to-pixel-art-wrapper .download-button:hover {
  background-color: #45a049;
}

.img-to-pixel-art-wrapper .button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  text-align: center;
  display: inline-block;
  line-height: 1.5;
  font-family: Arial, sans-serif;
  font-weight: normal;
  margin: 0;
  width: 180px; /* Set a fixed width for both buttons */
  box-sizing: border-box;
}

.img-to-pixel-art-wrapper .button:hover {
  background-color: #2980b9;
}

.img-to-src-wrapper  .img-to-src {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.img-to-src-wrapper .introduction {
  margin-bottom: 20px;
  line-height: 1.6;
}

.img-to-src-wrapper .getting-started {
  margin-top: 20px;
  margin-bottom: 20px;
}

.img-to-src-wrapper .getting-started h2 {
  margin-bottom: 10px;
  font-size: 1.2em;
  text-align: left;
  padding-left: 20px;
}

.getting-started ol {
  padding-left: 20px;
  list-style-type: decimal;
}

.img-to-src-wrapper .getting-started ul {
  padding-left: 20px;
  list-style-type: disc;
}

.img-to-src-wrapper .getting-started li {
  margin-bottom: 10px;
}

.img-to-src-wrapper .getting-started ul li {
  margin-bottom: 5px;
}

.img-to-src-wrapper .introduction strong {
  font-weight: bold;
}

/* Ensure consistent alignment */
.img-to-src-wrapper .introduction > p,
.img-to-src-wrapper .introduction > .getting-started {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.img-to-src-wrapper .introduction p {
  margin-bottom: 15px; /* Add space between paragraphs */
  text-align: left; /* Align text to the left */
  max-width: 800px; /* Limit the maximum width of the text container */
  margin: 0 auto; /* Center the container within the page */
  padding: 20px;
  line-height: 1.6; /* Improve readability by increasing line height */
}

.img-to-src-wrapper .introduction ol, .img-to-src-wrapper .introduction ul {
  margin-left: 20px;
  text-align: left;
}

.img-to-src-wrapper .introduction li {
  margin-bottom: 10px;
}

.img-to-src-wrapper .controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.img-to-src-wrapper .control-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-to-src-wrapper .control-group label {
  width: 100px;
  text-align: right;
  font-weight: bold;
}

.img-to-src-wrapper .slider-container {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
}

.img-to-src-wrapper .dimension-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.img-to-src-wrapper .dimension-slider:hover {
  opacity: 1;
}

.img-to-src-wrapper .dimension-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

.img-to-src-wrapper .dimension-slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #4CAF50;
  cursor: pointer;
}

.img-to-src-wrapper .dimension-input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.img-to-src-wrapper .dimension-unit {
  min-width: 30px;
}

.img-to-src-wrapper .file-input {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.img-to-src-wrapper .ascii-art-container-wrapper {
  position: relative;
  width: 100%;
  max-width: 1000px; /* Limit the maximum width */
  max-height: 1000px; /* Limit the maximum height */
  overflow: hidden; /* Hide overflow */
}

.img-to-src-wrapper .ascii-art-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px; /* Set a minimum height to prevent layout shift */
  max-height: 900px;
  width: 100%;
  margin-top: 20px;
  overflow: hidden; /* Hide overflow */

}

.img-to-src-wrapper .ascii-art {
  white-space: pre;
  font-family: monospace;
  font-size: 12px;
  color: #333;
  text-align: center;
}

.img-to-src-wrapper .copy-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.img-to-src-wrapper .copy-button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.img-to-src-wrapper .copy-button:hover {
  background-color: #45a049;
}

.img-to-src-wrapper .copy-success {
  margin-left: 10px;
  color: #4CAF50;
}

.img-to-src-wrapper .button-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.img-to-src-wrapper .reset-button,
.img-to-src-wrapper .copy-button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.img-to-src-wrapper .reset-button:hover,
.img-to-src-wrapper .copy-button:hover {
  background-color: #45a049;
}

.img-to-src-wrapper .copy-success {
  color: #4CAF50;
}

.img-to-src-wrapper .brightness-slider {
  width: 100%;
  margin: 10px 0;
}

/* src/App.css */

/* General Styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f8f9fa;
}

/* Navbar Styles */
.navbar {
  background-color: #007bff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-item {
  margin: 0 15px;
}

.navbar-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
}

.navbar-link:hover {
  color: #e9ecef;
  border-bottom: 2px solid #e9ecef;
}

/* App Container Styles */
.App {
  text-align: center;
  min-height: 100vh;
  padding: 20px;
}


.footer {
  text-align: center;
  padding: 20px;
  background-color: #007bff;
  margin-top: 40px;
}

.footer p {
  color :white;
  margin: 5px 0;
}

.footer a {
  color: #007bff;
  text-decoration: none;
  margin: 0 10px;
}

.footer a:hover {
  text-decoration: underline;
}

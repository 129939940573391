.img-to-src {
  text-align: center;
  padding: 20px;
}

.controls {
  margin-bottom: 20px;
}

.dimension-input {
  margin-left: 10px;
  padding: 5px;
  width: 60px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.file-input {
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.generate-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.generate-button:hover {
  background-color: #0056b3;
}

.ascii-art {
  white-space: pre;
  font-family: monospace;
  font-size: 10px;
  color: #333;
  margin-top: 20px;
  text-align: left;
}
